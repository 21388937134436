<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-card class="pb-16 pa-4">
      <div class="text-h2 primary--text font-weight-bold mb-4">
        {{ $t('privacyPolicy.title') }}
      </div>
      <p>{{ $t('privacyPolicy.intro') }}</p>
      <div
        v-for="(item,index) in $t('privacyPolicy.sections')"
        :key="index"
      >
        <b>{{ item.title }}</b>
        <p>
          {{ item.text }}
        </p>
        <div v-if="item?.items">
          <div
            v-for="(listItem,listItemIndex) in item.items"
            :key="listItemIndex"
          >
            <p>{{ listItem.text }}</p>
            <div v-if="listItem?.items">
              <ul
                v-for="(listText,ulIndex) in listItem.items"
                :key="ulIndex"
              >
                <li>
                  {{ listText }}
                  <div v-if="listItem.subItemKey === ulIndex && listItem.subItems ">
                    <ul
                      v-for="(subItemText,subItemIndex) in listItem.subItems"
                      :key="subItemIndex"
                    >
                      <li>{{ subItemText }}</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped></style>
